<template>
  <div>
    <keep-alive
      v-if="$route.meta != null && $route.meta.keepAlive"
      :key="$route.name"
    >
      <router-view />
    </keep-alive>
    <router-view v-else :key="$route.name" />
  </div>
</template>

<script>
export default {
  name: "Orders",
};
</script>
